<template>
  <div class="loading-floater">
    <img src="../../content/images/favicon.svg" id="logo" alt="smartyfile" />

    <div class="container">
      <form
        method="post"
        :action="'/sso/logout?_csrf=' + csrfToken"
        ref="logout"
      />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Logout",
  computed: {
    csrfToken() {
      const tokens = document.cookie
        .split(";")
        .filter(item => item.trim().startsWith("XSRF-TOKEN="));
      if (tokens) {
        const start = tokens[0].indexOf("=") + 1;
        return tokens[0].substring(start);
      }
      return "";
    },
  },
  mounted() {
    this.logout().then(this.$refs.logout.submit());
  },
  methods: {
    ...mapActions("auth", ["logout"]),
  },
};
</script>

<style lang="css" scoped>
.loading-floater {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 300px;
  animation: 5s infinite ease-in-out alternate b-icon-animation-fade;
}
</style>
