import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    nzbns: {},
  },
  mutations: {
    addNzbn(state, entry) {
      Vue.set(state.nzbns, entry.nzbn, entry.value);
    },
  },
  getters: {
    getNzbnDetails: state => nzbn => state.nzbns[nzbn],
  },
  actions: {
    getNzbnDetails({commit, getters}, nzbn) {
      const details = getters.getNzbnDetails(nzbn);
      if (details) {
        return new Promise(resolve => resolve(details));
      } else {
        return axios
          .get("/api/v1/nzbn/" + nzbn)
          .then(response =>
            commit("addNzbn", {nzbn: nzbn, value: response.data})
          )
          .then(() => getters.getNzbnDetails(nzbn));
      }
    },
  },
};
