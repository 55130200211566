import axios from "axios";

const axiosConfig = {headers: {"Content-Type": "application/json"}};

const usersUrl = "/api/v1/users";
const singleUserUrl = userId => usersUrl + "/" + userId;

export default {
  namespaced: true,
  actions: {
    patchUser({commit}, userData) {
      const url = singleUserUrl(userData.id);
      return axios.patch(url, userData, axiosConfig).then(result => {
        //patch current user in auth store
        commit("auth/PATCH_CURRENT_USER", result.data, {
          root: true,
        });
      });
    },
  },
};
