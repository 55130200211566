import Vue from "vue";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import setupInterceptor from "./shared/axios-interceptor";
import * as dateFilters from "./shared/filter";
import VueLodash from "vue-lodash";
import lodash from "lodash";

import "../content/scss/vendor.scss";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faCheckCircle,
  faClock,
  faMinusCircle,
  faSpinner,
  faTimesCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(
  faBan,
  faCheckCircle,
  faClock,
  faMinusCircle,
  faSpinner,
  faUsers,
  faTimesCircle
);

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueLodash, {lodash: lodash});
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
dateFilters.initFilters();
setupInterceptor();

new Vue({
  router,
  store, // this will inject the store instance to all child components.
  render: h => h(App),
}).$mount("#app");
