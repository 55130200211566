import axios from "axios";
import {dataMapper} from "@/utils/dataMapper";
import jwt from "jsonwebtoken";

function setSingleLineAddress(data) {
  if (data) {
    data.addresses_merged = [];
    if (data && data.addresses && data.addresses.length > 0) {
      data.addresses.forEach(addr => {
        const mergedAddress = dataMapper.singleLineAddress(addr);
        const addressObj = {tags: addr.tags, address: mergedAddress};
        data.addresses_merged.push(addressObj);
      });
    }
  }
}

export default {
  state: {
    currentUser: null,
    authenticated: false,
    token: null,
  },
  mutations: {
    SET_CURRENT_USER(state, newValue) {
      state.currentUser = newValue;
      setSingleLineAddress(state.currentUser);
      state.authenticated = !!newValue;
    },
    PATCH_CURRENT_USER(state, patchUser) {
      if (patchUser.id === state.currentUser.id) {
        for (const prop of Object.getOwnPropertyNames(patchUser)) {
          if (Object.prototype.hasOwnProperty.call(state.currentUser, prop)) {
            state.currentUser[prop] = patchUser[prop];
          }
        }
        setSingleLineAddress(state.currentUser);
      }
    },
    SET_TOKEN: (state, token) => (state.token = token),
  },
  getters: {
    // Whether the user is currently logged in.
    loggedIn: state => state.authenticated,
    isViewerOf: state => orgId =>
      state.currentUser.orgToPermissions[orgId].includes("OF_VIEW"),
    isAdminOf: state => orgId =>
      state.currentUser.orgToPermissions[orgId].includes("ADMIN"),
    getCurrentUserId: state => state.currentUser.id,
    getCurrentUser: state => state.currentUser,
    isMemberOf: state => orgId => state.currentUser.orgToPermissions[orgId],
    getToken: state => state.token,
  },
  actions: {
    // Logs out the current user.
    logout({commit}) {
      commit("SET_CURRENT_USER", null);
    },
    loadUserDetails({commit}) {
      return axios
        .get("/api/v1/session")
        .then(response => {
          commit("SET_CURRENT_USER", response.data);
          return true;
        })
        .catch(() => console.log("Failed to retrieve user details"));
    },
    expireSession({commit}) {
      return axios
        .post("/sso/logout")
        .then(response => {
          commit("SET_CURRENT_USER");
          return true;
        })
        .catch(() => console.log("Failed to expire session"));
    },
    extendSession() {
      return axios
        .post("/api/v1/session/extend")
        .then(response => {
          return true;
        })
        .catch(() => console.log("Failed to extend session"));
    },
    validate({dispatch, state}) {
      if (state.authenticated) {
        return Promise.resolve(true);
      }
      const session = document.cookie
        .split(";")
        .filter(item => item.trim().startsWith("SESSION="));
      if (session) {
        // retrieve user data
        return dispatch("loadUserDetails");
      } else {
        return Promise.resolve(false);
      }
    },
    getToken({commit, getters}) {
      const token = getters.getToken;
      if (token) {
        //check token expiry date
        const decoded = jwt.decode(token, {complete: true});
        const exp = decoded.payload.exp * 1000;
        if (exp - Date.now() > 30000) {
          //return cached token if it is valid for more than 30s
          return Promise.resolve(token);
        }
      }
      return axios
        .get("api/v1/session/token")
        .then(result => commit("SET_TOKEN", result.data))
        .then(() => getters.getToken);
    },
  },
  namespaced: true,
};
