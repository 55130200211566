import axios from "axios";

export default {
  namespaced: true,
  state: {
    joinRequests: [],
  },
  mutations: {
    setJoinRequests(state, requests) {
      state.joinRequests = requests;
    },
  },
  getters: {
    getJoinRequestById: state => id => state.joinRequests.find(jr => jr.id === id),
  },
  actions: {
    fetchJoinRequests({commit}, orgId) {
      const url = "/api/v1/orgs/" + orgId + "/join";
      return axios
        .get(url)
        .then(response => {
          if (response.data.content) {
            const requests = response.data.content;
            commit("setJoinRequests", requests);
          } else {
            console.log("No content received");
          }
        })
        .catch(() => console.error("Error fetching requests"));
    },
    approve({commit}, data) {
      const url = "/api/v1/orgs/" + data.orgId + "/join/" + data.requestId;
      const member = {
        email: "irrelevant@example.com",
        isFullAccess: false,
        canSubmit: false,
        group: "READER",
      };
      return axios.post(url, member, axiosConfig("application/json"));
    },
    reject({commit}, data) {
      const url =
        "/api/v1/orgs/" + data.orgId + "/join/" + data.requestId + "/reject";
      return axios.post(url, data.rejectionMessage, axiosConfig("text/plain"));
    },
    fetchJoinRequestsByUser({commit}, userId) {
      const url = "/api/v1/users/" + userId + "/requests";
      return axios.get(url);
    },
  },
};

const axiosConfig = type => {
  return {
    headers: {
      "Content-Type": type,
    },
  };
};