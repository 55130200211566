<template>
  <div id="app">
    <div id="header">
      <div class="container-lg d-flex align-items-center">
        <a href="/">
          <svg
            id="smartyfile-logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 270 60"
          >
            <path
              class="red_book"
              d="M16.93,60.97l-7.29,0.05c-2.03,0.01-3.69-1.72-3.69-3.85L5.94,11.25c0-2.13,1.66-3.89,3.69-3.9l7.29-0.05 c2.03-0.01,3.69,1.72,3.69,3.85l0.01,45.91C20.62,59.2,18.96,60.95,16.93,60.97z"
            />
            <path
              class="blue_book"
              d="M35.92,60.54l-7.27,0.49c-2.03,0.14-3.79-1.49-3.92-3.62l-2.81-45.82c-0.13-2.13,1.42-3.98,3.44-4.12l7.27-0.49 c2.03-0.14,3.79,1.49,3.92,3.62l2.81,45.82C39.5,58.55,37.95,60.4,35.92,60.54z"
            />
            <path
              class="green_book"
              d="M54.92,59.84l-7.23,0.96c-2.01,0.27-3.87-1.24-4.13-3.36l-5.5-45.55c-0.26-2.11,1.18-4.06,3.2-4.33l7.23-0.96 c2.01-0.27,3.87,1.24,4.13,3.36l5.5,45.55C58.37,57.62,56.93,59.57,54.92,59.84z"
            />
            <g class="letter">
              <path
                d="M87.95,17.17c-1.64-1.11-8.56-3.37-8.66,0.24c-0.14,4.42,13.01,0.1,13.01,11.17c0,5.46-4.56,8.11-10.4,8.11 c-4.04,0-7.9-1.08-10.51-2.85l3.44-5.32c1.46,1.53,10.02,3.97,10.05,0.07c0.03-4.63-13.05-0.77-13.05-11.17c0-4.56,4.49-8,10.05-8 c3.86,0,7.06,1.04,9.43,2.71L87.95,17.17z"
              />
              <path
                d="M93.91,36.1V15.95h7.1v2.85l0.17,0.07c1.04-1.91,2.89-3.51,5.22-3.51c3.1,0,5.01,1.7,5.67,3.48 c1.04-1.91,2.96-3.48,5.6-3.48c4.84,0,7.69,3.2,7.69,7.58V36.1h-7.1V24.44c0-1.43-1.11-2.54-2.54-2.54s-2.54,1.11-2.54,2.54V36.1 h-7.1V24.44c0-1.43-1.11-2.54-2.54-2.54s-2.54,1.11-2.54,2.54V36.1H93.91z"
              />
              <path
                d="M142.69,18.84h0.14v-2.89h7.1V36.1h-7.1v-2.85h-0.14c-0.9,1.74-3.31,3.44-6.37,3.44c-5.77,0-9.36-4.7-9.36-10.65 s3.58-10.68,9.36-10.68C139.38,15.36,141.78,17.1,142.69,18.84z M138.65,21.9c-2.37,0-4.11,1.95-4.11,4.14 c0,2.19,1.74,4.14,4.11,4.14s4.21-1.95,4.24-4.14C142.86,23.85,141.02,21.9,138.65,21.9z"
              />
              <path
                d="M167.99,16.34l-2.71,6.4c-0.45-0.28-1.53-0.45-2.09-0.45c-2.26,0-3.86,1.5-3.86,4.14v9.67h-7.1V15.95h6.89v3.13l0.1,0.07 c0.7-1.81,2.37-3.83,4.73-3.83C165.45,15.33,166.7,15.71,167.99,16.34z"
              />
              <path
                d="M177.69,29.59c0,0.87,0.94,1.53,1.81,1.53c0.49,0,0.97-0.07,1.29-0.28l1.22,5.11c-0.94,0.49-3.41,0.77-4.45,0.77 c-3.79,0-6.96-2.5-6.96-6.02v-9.5h-1.81v-5.25h1.81V11.4h7.1v4.56h3.69v5.25h-3.69V29.59z"
              />
              <path
                d="M195.19,42.05h-7.76l2.64-5.95l-7.72-20.14h7.93l3.44,12.94h0.21l3.44-12.94h7.93L195.19,42.05z"
              />
              <path
                d="M214.14,15.99v4.07h9.5v5.98h-9.5V36.1h-7.1V10.01h18.96v5.98H214.14z"
              />
              <path
                d="M231.33,6.91c2.26,0,4.14,1.71,4.14,3.93c0,2.19-1.88,3.93-4.14,3.93c-2.23,0-4.11-1.74-4.11-3.93 C227.22,8.61,229.1,6.91,231.33,6.91z M227.78,15.95h7.1V36.1h-7.1V15.95z"
              />
              <path d="M238.25,10.01h7.1V36.1h-7.1V10.01z" />
              <path
                d="M254.15,28.03c0.35,1.43,1.77,2.96,4.38,2.96c2.3,0,3.86-0.8,5.11-1.77l3.69,3.79c-2.3,2.05-4.63,3.69-8.98,3.69 c-7.2-0.14-11.34-4.63-11.34-10.68c0-6.12,4.94-10.65,11.06-10.65s11.06,4.52,11.06,10.65c0,0.7-0.07,1.39-0.21,2.02H254.15z M254.15,23.99h7.86c-0.49-1.43-1.98-2.82-3.93-2.82C256.1,21.17,254.61,22.56,254.15,23.99z"
              />
            </g>
            <g class="letter">
              <path
                d="M80.05,47.03c-0.93-0.62-2.12-1.02-3.43-1.02c-1.73,0-3.05,1-3.05,2.43c0,4.05,7.76,0.95,7.76,6.67 c0,2.37-2.14,3.98-4.71,3.98c-1.79,0-3.45-0.57-4.68-1.5l0.75-1.06c1,0.79,2.37,1.31,3.93,1.31c1.88,0,3.32-1.04,3.32-2.74 c0-4.49-7.76-1.35-7.76-6.67c0-2.12,2.03-3.67,4.44-3.67c1.59,0,3.03,0.46,4.16,1.22L80.05,47.03z"
              />
              <path
                d="M83.67,48.2v-2.39h1.35v2.39h2.19v1.26h-2.19v7.27c0,0.86,0.51,1.11,1.15,1.11c0.33,0,0.55-0.11,0.79-0.27l0.46,1.15 c-0.38,0.22-0.79,0.37-1.24,0.37c-1.39,0-2.5-0.79-2.5-2.32v-7.31h-1.24V48.2H83.67z"
              />
              <path
                d="M88.14,53.48c0-3.09,2.58-5.59,5.7-5.59c3.14,0,5.7,2.5,5.7,5.59c0,3.11-2.56,5.61-5.7,5.61 C90.72,59.09,88.14,56.58,88.14,53.48z M98.15,53.48c0-2.39-1.9-4.35-4.31-4.35c-2.37,0-4.31,1.95-4.31,4.35 c0,2.41,1.94,4.37,4.31,4.37C96.25,57.84,98.15,55.89,98.15,53.48z"
              />
              <path
                d="M106.79,48.09l-0.42,1.22c-0.27-0.09-0.53-0.15-0.82-0.15c-1.57,0-2.83,1.41-2.83,2.96v6.65h-1.35V48.2h1.35v1.55h0.04 c0.48-0.95,1.46-1.86,2.79-1.86C105.97,47.89,106.39,47.96,106.79,48.09z"
              />
              <path
                d="M118.22,54.12h-9.88c0.31,2.1,2.08,3.73,4.27,3.73c1.66,0,3.07-0.91,3.82-2.28l1.02,0.71c-0.99,1.64-2.79,2.81-4.84,2.81 c-3.1,0-5.64-2.52-5.64-5.61s2.54-5.59,5.64-5.59c3.12,0,5.66,2.5,5.66,5.59C118.27,53.7,118.26,53.92,118.22,54.12z M116.9,52.86 c-0.31-2.08-2.1-3.73-4.29-3.73s-3.96,1.64-4.27,3.73H116.9z"
              />
              <path
                d="M125.71,44.77c0.51,0,0.93,0.42,0.93,0.93s-0.42,0.93-0.93,0.93c-0.51,0-0.93-0.42-0.93-0.93S125.19,44.77,125.71,44.77z M126.38,48.2v10.58h-1.35V48.2H126.38z"
              />
              <path
                d="M129.08,48.2v-2.39h1.35v2.39h2.19v1.26h-2.19v7.27c0,0.86,0.51,1.11,1.15,1.11c0.33,0,0.55-0.11,0.79-0.27l0.46,1.15 c-0.38,0.22-0.79,0.37-1.24,0.37c-1.39,0-2.5-0.79-2.5-2.32v-7.31h-1.24V48.2H129.08z"
              />
              <path
                d="M135.17,48.05c0.57,0,1.02,0.46,1.02,1.02c0,0.57-0.46,1.02-1.02,1.02c-0.57,0-1.02-0.46-1.02-1.02 C134.14,48.51,134.6,48.05,135.17,48.05z M135.48,59.54v-0.68c-0.09,0.04-0.2,0.05-0.31,0.05c-0.57,0-1.02-0.46-1.02-1.02 s0.46-1.02,1.02-1.02c0.57,0,1.02,0.46,1.02,1.02v1.64c0,1.04-0.68,1.39-1.5,1.39h-0.22v-0.62h0.22 C135.13,60.31,135.48,60.11,135.48,59.54z"
              />
              <path
                d="M151.05,47.03c-0.93-0.62-2.12-1.02-3.43-1.02c-1.73,0-3.05,1-3.05,2.43c0,4.05,7.76,0.95,7.76,6.67 c0,2.37-2.14,3.98-4.71,3.98c-1.79,0-3.45-0.57-4.68-1.5l0.75-1.06c1,0.79,2.37,1.31,3.93,1.31c1.88,0,3.32-1.04,3.32-2.74 c0-4.49-7.76-1.35-7.76-6.67c0-2.12,2.03-3.67,4.44-3.67c1.59,0,3.03,0.46,4.16,1.22L151.05,47.03z"
              />
              <path
                d="M154.16,45.08h1.35v4.91h0.04c0.48-1,1.92-2.1,3.58-2.1c2.67,0,4.16,1.74,4.16,4.22v6.67h-1.35v-6.67 c0-1.77-1.17-2.98-2.92-2.98c-1.72,0-3.51,1.41-3.51,3.12v6.52h-1.35V45.08z"
              />
              <path
                d="M175.16,56.64h-0.04c-0.84,1.26-2.3,2.45-4.44,2.45c-3.12,0-5.57-2.5-5.57-5.61c0-3.09,2.45-5.59,5.57-5.59 c2.14,0,3.6,1.17,4.44,2.45h0.04V48.2h1.35v10.58h-1.35V56.64z M170.81,49.13c-2.41,0-4.31,1.94-4.31,4.35 c0,2.43,1.9,4.37,4.31,4.37c2.39,0,4.35-1.94,4.35-4.37C175.16,51.07,173.21,49.13,170.81,49.13z"
              />
              <path
                d="M184.13,48.09l-0.42,1.22c-0.27-0.09-0.53-0.15-0.82-0.15c-1.57,0-2.83,1.41-2.83,2.96v6.65h-1.35V48.2h1.35v1.55h0.04 c0.48-0.95,1.46-1.86,2.79-1.86C183.3,47.89,183.72,47.96,184.13,48.09z"
              />
              <path
                d="M195.56,54.12h-9.88c0.31,2.1,2.08,3.73,4.27,3.73c1.66,0,3.07-0.91,3.82-2.28l1.02,0.71c-0.99,1.64-2.79,2.81-4.84,2.81 c-3.1,0-5.64-2.52-5.64-5.61s2.54-5.59,5.64-5.59c3.12,0,5.66,2.5,5.66,5.59C195.61,53.7,195.6,53.92,195.56,54.12z M194.24,52.86 c-0.31-2.08-2.1-3.73-4.29-3.73s-3.96,1.64-4.27,3.73H194.24z"
              />
              <path
                d="M203.04,44.77c0.51,0,0.93,0.42,0.93,0.93s-0.42,0.93-0.93,0.93c-0.51,0-0.93-0.42-0.93-0.93S202.53,44.77,203.04,44.77z M203.72,48.2v10.58h-1.35V48.2H203.72z"
              />
              <path
                d="M206.42,48.2v-2.39h1.35v2.39h2.19v1.26h-2.19v7.27c0,0.86,0.51,1.11,1.15,1.11c0.33,0,0.55-0.11,0.79-0.27l0.46,1.15 c-0.38,0.22-0.79,0.37-1.24,0.37c-1.39,0-2.5-0.79-2.5-2.32v-7.31h-1.24V48.2H206.42z"
              />
              <path
                d="M212.5,48.05c0.57,0,1.02,0.46,1.02,1.02c0,0.57-0.46,1.02-1.02,1.02c-0.57,0-1.02-0.46-1.02-1.02 C211.48,48.51,211.94,48.05,212.5,48.05z M212.81,59.54v-0.68c-0.09,0.04-0.2,0.05-0.31,0.05c-0.57,0-1.02-0.46-1.02-1.02 s0.46-1.02,1.02-1.02c0.57,0,1.02,0.46,1.02,1.02v1.64c0,1.04-0.68,1.39-1.5,1.39h-0.22v-0.62h0.22 C212.47,60.31,212.81,60.11,212.81,59.54z"
              />
              <path
                d="M220.65,53.79v-8.71H222v8.71c0,2.23,1.75,4.05,3.95,4.05c2.17,0,3.93-1.83,3.93-4.05v-8.71h1.35v8.71 c0,2.92-2.37,5.3-5.28,5.3C223.02,59.09,220.65,56.71,220.65,53.79z"
              />
              <path
                d="M233.74,56.86c0.93,0.55,2.17,0.99,3.51,0.99c1.86,0,2.98-0.79,2.98-2.03c0-3.4-7.03-0.58-7.03-5.11 c0-1.88,1.95-2.83,4.05-2.83c1.52,0,2.96,0.4,4.02,1.04l-0.6,1.08c-0.86-0.49-2.16-0.88-3.42-0.88c-1.63,0-2.67,0.66-2.67,1.55 c0,3.27,7.03,0.31,7.03,5.22c0,1.94-1.72,3.2-4.37,3.2c-1.57,0-3.09-0.42-4.2-1.13L233.74,56.86z"
              />
              <path
                d="M254.32,54.12h-9.88c0.31,2.1,2.08,3.73,4.27,3.73c1.66,0,3.07-0.91,3.82-2.28l1.02,0.71c-0.99,1.64-2.79,2.81-4.84,2.81 c-3.1,0-5.64-2.52-5.64-5.61s2.54-5.59,5.64-5.59c3.12,0,5.66,2.5,5.66,5.59C254.38,53.7,254.36,53.92,254.32,54.12z M253.01,52.86 c-0.31-2.08-2.1-3.73-4.29-3.73c-2.19,0-3.96,1.64-4.27,3.73H253.01z"
              />
              <path
                d="M261.81,44.77c0.51,0,0.93,0.42,0.93,0.93s-0.42,0.93-0.93,0.93c-0.51,0-0.93-0.42-0.93-0.93S261.3,44.77,261.81,44.77z M262.49,48.2v10.58h-1.35V48.2H262.49z"
              />
              <path
                d="M265.19,48.2v-2.39h1.35v2.39h2.19v1.26h-2.19v7.27c0,0.86,0.51,1.11,1.15,1.11c0.33,0,0.55-0.11,0.79-0.27l0.46,1.15 c-0.38,0.22-0.79,0.37-1.24,0.37c-1.39,0-2.5-0.79-2.5-2.32v-7.31h-1.24V48.2H265.19z"
              />
            </g>
          </svg>
        </a>
        <b-alert v-if="showSessionTimeoutMsg"
                 id="session-timeout-banner"
                 class="d-flex align-items-center justify-content-between m-0 ml-5 w-100"
                 tabindex="0"
                 show variant="danger">
          <div>
            <font-awesome-icon :icon="['fas', 'clock']"></font-awesome-icon>
            <strong class="ml-1">Timeout Warning:</strong> Your session will expire in
            <span id="session-countdown">{{ countdownSeconds }}</span> seconds
          </div>
          <div class="ml-1">
            <b-button class="btn session-timeout-extend mr-1" variant="success" v-on:click="extendSession">Extend Session</b-button>
            <b-button class="btn session-timeout-logout" variant="danger" v-on:click="logout">Log Out</b-button>
          </div>
        </b-alert>
      </div>
    </div>
    <nav id="nav">
      <div class="container-lg">
        <div class="main" v-if="authenticated">
          <ul>
            <li>
              <router-link to="/profile">My Profile</router-link>
            </li>
            <li>
              <router-link
                  to="/organisation"
                  :class="{
                'router-link-exact-active': subIsActive('/organisation'),
              }"
              >My Organisations</router-link
              >
            </li>
            <li>
              <router-link to="/submissions">My Submissions</router-link>
            </li>
          </ul>
        </div>
        <div class="main" v-else>
          <ul>
            <li>
              <a class="login" v-on:click="login">Login</a>
            </li>
          </ul>
        </div>
        <div class="secondary" v-if="authenticated">
          Logged in as {{ currentUser.name }}
          <router-link to="/logout">Logout</router-link>
        </div>
      </div>
    </nav>
    <div class="content">
      <div class="container-lg">
        <router-view />
      </div>
    </div>
    <footer class="primaryBg pt-4">
      <div class="container-lg">
        <div class="row align-items-center">
          <div class="col-lg-7 col-md-6">
            <div class="row">
              <div class="col-lg-5 col-md-12 pb-3">
                <h5>Contact</h5>
                <a href="mailto:service@smartyfile.com.au">
                  service@smartyfile.com.au
                </a>
                <span>03 9320 6888</span>
              </div>
              <div class="col-lg-3 col-md-12 pb-3">
                <h5>Resources</h5>
                <ul>
                  <li>
                    <a href="https://applicanthelp.smartygrants.com.au/smartyfile/">Help</a>
                  </li>
                  <li>
                    <a href="https://applicanthelp.smartygrants.com.au/smartyfile/#SmartyFile-FAQs">FAQs</a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-4 col-md-12 pb-3">
                <h5>More</h5>
                <ul>
                  <li>
                    <a href="https://www.smartyfile.com.au">About SmartyFile</a>
                  </li>
                  <li>
                    <a href="https://www.ourcommunity.com.au/general/faq.jsp#tac">Copyright</a>
                  </li>
                  <li>
                    <a href="https://www.ourcommunity.com.au/privacy">Privacy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 text-md-right text-center">
            <a href="https://www.ourcommunity.com.au">
              <img
                src="../content/images/oc-logo.svg"
                alt="Our Community"
                id="oc-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
    <b-toast
      id="file-prefill-spam"
      title="New Feature!"
      no-auto-hide
      solid
      variant="primary"
      @hidden="stopSpam()"
    >
      <p>
        Store files under your personal and organisation profiles to access when
        completing SmartyGrants forms.
      </p>
      <p>
        Personal contact details can now be pre-filled into all applications.
      </p>
      <p>
        See the
        <a
          href="http://applicanthelp.smartygrants.com.au/smartyfile/#SmartyFile-Filestorage"
        >
          Applicant Help Guide
        </a>
        for more information.
      </p>
    </b-toast>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

const AppTitle = "SmartyFile";
const WarningTitle = "** Warning ** — " + AppTitle;
let sessionTimer = null;

export default {
  name: "app",
  appTitle: AppTitle,
  data() {
    return {
      countdownSeconds: this.sessionTimeoutCountdownSecs,
      showSessionTimeoutMsg: false,
      sessionHasExpired: false,
    };
  },
  computed: {
    ...mapState("auth", {
      authenticated: state => state.authenticated,
      currentUser: state => state.currentUser,
    }),
    ...mapState("configStore", {
      sessionTimeoutSecs: state =>
        state.sessionTimeoutConfig.sessionTimeoutSecs,
      sessionTimeoutCountdownSecs: state =>
        state.sessionTimeoutConfig.sessionTimeoutCountdownSecs,
    }),
  },
  mounted() {
    this.fetchMySubmissionsUrl();
    this.fetchSessionTimeoutConfig().then(() => {
      this.setupAjaxListener();
      this.startSessionCountdown();
    });
   this.showNewFeatureToast()
  },
  methods: {
    ...mapActions("configStore", [
      "fetchMySubmissionsUrl",
      "fetchSessionTimeoutConfig",
    ]),
    ...mapActions("auth", ["extendSession"]),
    setIdle(idle) {
      this.idle = idle;
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    logout() {
      this.$router.push("/logout");
    },
    sessionExpired() {
      this.goToSessionExpired();
      this.sessionHasExpired = true;
    },
    login() {
      if (this.sessionHasExpired) {
        this.goBack();
      } else {
        this.goToLogin();
      }
    },
    goBack() {
      this.$router.back();
    },
    goToLogin() {
      this.$router.push({name: "login"});
    },
    goToSessionExpired() {
      this.$router.push({name: "sessionExpired"});
    },
    startSessionCountdown() {
      const expiryTime = Date.now() + this.sessionTimeoutSecs * 1000;
      let alertFocussed = false;
      sessionTimer = window.setInterval(() => {
        if (Date.now() >= expiryTime - this.sessionTimeoutCountdownSecs * 1000) {
          this.countdownSeconds = Math.trunc((expiryTime - Date.now()) / 1000);
          this.showSessionTimeoutMsg = true;
          let alert = document.getElementById("session-timeout-banner");
          if (!alertFocussed && alert) {
            alert.focus();
            alertFocussed = true;
          }
          document.title = WarningTitle;
          if (Date.now() >= expiryTime) {
            document.title = AppTitle;
            this.clearSessionCountdown();
            this.sessionExpired();
          }
        }
      }, 1000);
    },
    clearSessionCountdown() {
      if (sessionTimer) {
        clearInterval(sessionTimer);
        sessionTimer = null;
      }
      this.showSessionTimeoutMsg = false;
    },
    setupAjaxListener() {
      const ajaxListener = {};
      ajaxListener.tempOpen = XMLHttpRequest.prototype.open;
      ajaxListener.tempSend = XMLHttpRequest.prototype.send;
      ajaxListener.callback = () => {
        if (ajaxListener.url.startsWith("/api/")) {
          this.clearSessionCountdown();
          this.startSessionCountdown();
        }
      };
      XMLHttpRequest.prototype.open = function (a, b) {
        if (!a) {
          a = "";
        }
        if (!b) {
          b = "";
        }
        ajaxListener.method = a;
        ajaxListener.url = b;
        ajaxListener.tempOpen.apply(this, arguments);
      };

      XMLHttpRequest.prototype.send = function (a, b) {
        ajaxListener.tempSend.apply(this, arguments);
        ajaxListener.callback();
      };
    },
    showNewFeatureToast() {
      // Check if the user has seen this toast yet
      const featureName = "file_prefill";
      const cookies = document.cookie.split(";");
      const cookie = cookies.find(cookie => cookie.includes(featureName));
      if (!cookie) {
        this.$bvToast.show("file-prefill-spam");
      }
    },
    stopSpam() {
      const expiryMillis = 365 * 24 * 3600 * 1000;
      const expiryDate = new Date(new Date().getTime() + expiryMillis);
      const expiry = "expires=" + expiryDate.toUTCString();
      document.cookie = "file_prefill=hide;" + expiry + ";path=/";
    },
  },
};
</script>

<style lang="scss">
@import "../content/scss/bootstrap-variables";

html {
  font-size: 16px;
}
/** Styles specific to the this component structure **/
body {
  height: 100%;
  min-height: 100vh;
}

#app {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#smartyfile-logo {
  width: 224px;
  padding: 1.2rem 0;
  margin-left: -3px;

  .blue_book {
    fill: $primary;
  }

  .green_book {
    fill: $success;
  }

  .red_book {
    fill: $danger;
  }

  .letter {
    fill: $dark_grey;
  }
}

nav#nav {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.06);
  z-index: 50;

  > div {
    flex-grow: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    line-height: 2em;

    .main {
      display: inline-flex;
      align-items: center;
      ul {
        list-style: none;
        display: flex;
        margin: 0;
        margin-block: unset;
        padding: 0;
        li {
          padding-right: 1em;
        }
      }
    }

    .secondary {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: white;
        margin-left: 1em;
        padding-left: 1em;
        padding-right: 1em;
        background-color: $dark_teal;
      }
      a:hover {
        background-color: darken($dark_teal, 15%);
      }
      a.sgUrl {
        background-color: $primary;
      }
      a.sgUrl:hover {
        background-color: darken($primary, 10%);
      }
    }
  }

  a {
    padding: 5px 0;
    color: $of_blue;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
  a.router-link-exact-active {
    color: #495057;
    font-weight: 600;
  }
}

.content {
  flex-grow: 1;
  padding: 30px 0;
}

#oc-logo {
  width: 300px;
}

.primaryBg {
  background-color: $primary;
  color: $light-blue-text;
}

footer {
  ul {
    list-style: none;
    margin: 0;
    margin-block: unset;
    padding: 0;
  }

  h5 {
    font-weight: 600;
  }

  a {
    color: $light-blue-text;
    text-decoration: underline;
    display: block;
  }

  a:hover {
    color: lighten($light-blue-text, 15%);
  }
}

a.login {
  cursor: pointer;
}

#session-timeout-banner:focus {
  outline: none;
}

.session-timeout-logout {
  color: white !important;
}

.session-timeout-extend {
  color: white !important;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
