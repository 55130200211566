import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    abns: {},
  },
  mutations: {
    addAbn(state, entry) {
      Vue.set(state.abns, entry.abn, entry.value);
    },
  },
  getters: {
    getAbnDetails: state => abn => state.abns[abn],
  },
  actions: {
    getAbnDetails({commit, getters}, abn) {
      const details = getters.getAbnDetails(abn);
      if (details) {
        return new Promise(resolve => resolve(details));
      } else {
        return axios
          .get("/api/v1/abn/" + abn)
          .then(response => commit("addAbn", {abn: abn, value: response.data}))
          .then(() => getters.getAbnDetails(abn));
      }
    },
  },
};
