import axios from "axios";

const configUrl = "/api/v1/config";

export default {
  namespaced: true,
  state: {
    mySubmissionsUrl: null,
    sgApplyUrl: null,
    hereMapsConfig: null,
    sessionTimeoutConfig: null,
    sfApplicantUrl: null,
    fileStorageConfig: null,
  },
  mutations: {
    setMySubmissionsUrl(state, url) {
      state.mySubmissionsUrl = url;
    },
    setSgApplyUrl(state, url) {
      state.sgApplyUrl = url;
    },
    setHereMapsConfig(state, config) {
      state.hereMapsConfig = config;
    },
    setSessionTimeoutConfig(state, sessionTimeoutConfig) {
      state.sessionTimeoutConfig = sessionTimeoutConfig;
    },
    setSfApplicantUrl(state, url) {
      state.sfApplicantUrl = url;
    },
    setFileStorageConfig(state, fileStorageConfig) {
      state.fileStorageConfig = fileStorageConfig;
    },
  },
  getters: {
    getSessionTimeoutConfig: state => state.sessionTimeoutConfig,
  },
  actions: {
    fetchFileStorageConfig({commit, state}) {
      const config = state.fileStorageConfig;
      if (config) {
        return new Promise(resolve => resolve(config));
      } else {
        return axios
          .get(configUrl + "/fileStorageConfig")
          .then(response => {
            if (response.data) {
              commit("setFileStorageConfig", response.data);
              return response.data;
            } else {
              return Promise.reject("No file storage configs defined");
            }
          })
          .catch(() => console.error("Error fetching requests"));
      }
    },
    fetchMySubmissionsUrl({commit, state}) {
      const url = state.mySubmissionsUrl;
      if (url) {
        return new Promise(resolve => resolve(url));
      } else {
        return axios
          .get(configUrl + "/mysubmissionsurl")
          .then(response => {
            if (response.data) {
              commit("setMySubmissionsUrl", response.data);
              return response.data;
            } else {
              return Promise.reject("No url defined");
            }
          })
          .catch(() => console.error("Error fetching requests"));
      }
    },
    fetchSgApplyUrl({commit, state}) {
      const url = state.sgApplyUrl;
      if (url) {
        return new Promise(resolve => resolve(url));
      } else {
        return axios
          .get(configUrl + "/sgapplyurl")
          .then(response => {
            if (response.data) {
              commit("setSgApplyUrl", response.data);
              return response.data;
            } else {
              return Promise.reject("No url defined");
            }
          })
          .catch(() => console.error("Error fetching requests"));
      }
    },
    fetchHereMapsConfig({commit, state}) {
      const config = state.hereMapsConfig;
      if (config) {
        return new Promise(resolve => resolve(config));
      } else {
        return axios
          .get(configUrl + "/heremapsconfig")
          .then(response => {
            if (response.data) {
              commit("setHereMapsConfig", response.data);
              return response.data;
            } else {
              return Promise.reject("No properties defined");
            }
          })
          .catch(() => console.error("Error fetching requests"));
      }
    },
    fetchSessionTimeoutConfig({commit, state}) {
      const sessionTimeoutConfig = state.sessionTimeoutConfig;
      if (sessionTimeoutConfig) {
        return new Promise(resolve => resolve(sessionTimeoutConfig));
      } else {
        return axios
          .get(configUrl + "/sessionTimeoutConfig")
          .then(response => {
            if (response.data) {
              commit("setSessionTimeoutConfig", response.data);
              return response.data;
            } else {
              return Promise.reject("No sessionTimeoutConfig defined");
            }
          })
          .catch(() => console.error("Error fetching requests"));
      }
    },
    fetchSfApplicantUrl({commit, state}) {
      const url = state.sfApplicantUrl;
      if (url) {
        return new Promise(resolve => resolve(url));
      } else {
        return axios
          .get(configUrl + "/sfapplicanturl")
          .then(response => {
            if (response.data) {
              commit("setSfApplicantUrl", response.data);
              return response.data;
            } else {
              return Promise.reject("No url defined");
            }
          })
          .catch(() => console.error("Error fetching requests"));
      }
    },
  },
};
