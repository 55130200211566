import axios from "axios";
import {dataMapper} from "@/utils/dataMapper";

const axiosConfig = {headers: {"Content-Type": "application/json"}};

/** URL definitions */
const orgsUrl = "/api/v1/orgs";
const singleOrgUrl = orgId => orgsUrl + "/" + orgId;
const membersUrl = orgId => singleOrgUrl(orgId) + "/members";
const usersUrl = orgId => singleOrgUrl(orgId) + "/users";

function prepOrgListData(res) {
  let preppedData = [];
  if (res && res.data && res.data.content) {
    preppedData = res.data.content.map(x => {
      return prepOrgObjData(x);
    });
  }
  return preppedData;
}

function prepOrgObjData(x) {
  if (x.addresses) {
    x.addresses_merged = [];
    if (x.addresses.length > 0) {
      x.addresses.forEach(y => {
        const mergedAddress = dataMapper.singleLineAddress(y);
        const addressObj = {tags: y.tags, address: mergedAddress};
        x.addresses_merged.push(addressObj);
      });
    }
  }

  if (x.headOfOrg) {
    x.headOfOrg.addresses_merged = [];
    if (x.headOfOrg.addresses && x.headOfOrg.addresses.length > 0) {
      x.headOfOrg.addresses.forEach(y => {
        const mergedAddress = dataMapper.singleLineAddress(y);
        const addressObj = {tags: y.tags, address: mergedAddress};
        x.headOfOrg.addresses_merged.push(addressObj);
      });
    }
  } else {
    //Ensure we have a headOfOrg endpoint
    x.headOfOrg = {
      addresses_merged: [],
      addresses: [],
      emails: [],
      phoneNumbers: [],
      websites: [],
    };
  }
  return x;
}

export default {
  namespaced: true,
  state: {
    orgs: [],
    isLoading: true,
  },
  mutations: {
    loadFinished: state => (state.isLoading = false),
    addOrg: (state, newOrg) => state.orgs.push(newOrg),
    deleteOrg: (state, orgId) => {
      const pos = state.orgs.findIndex(org => org.id === orgId);
      if (pos >= 0) {
        state.orgs.splice(pos, 1);
      }
    },
    setOrgs: (state, orgs) => (state.orgs = orgs),
    /** Replace the org */
    editOrg(state, updatedOrg) {
      const pos = state.orgs.findIndex(org => org.id === updatedOrg.id);
      if (pos >= 0) {
        state.orgs.splice(pos, 1, updatedOrg);
      }
    },
    /** Update the org in place */
    patchOrg(state, patchOrg) {
      const org = state.orgs.find(org => org.id === patchOrg.id);
      state.orgs.splice(state.orgs.indexOf(org), 1, patchOrg);
    },
  },
  getters: {
    getOrgById: state => id => state.orgs.find(org => org.id === id),
    getOrgsByAbn: state => abn => {
      return state.orgs.filter(
        org => org.abn != null && org.abn.trim() === abn.trim()
      );
    },
    getOrgsByNzbn: state => nzbn => {
      return state.orgs.filter(
        org => org.nzbn != null && org.nzbn.trim() === nzbn.trim()
      );
    },
  },
  actions: {
    fetchOrgs({commit}) {
      return axios
        .get(orgsUrl)
        .then(response => {
          if (response.data.content) {
            commit("setOrgs", prepOrgListData(response));
          } else {
            console.log("No content received");
          }
          commit("loadFinished");
        })
        .catch(error => console.error("Get orgs error: " + error));
    },
    fetchOrg({commit, getters}, orgId) {
      const localOrg = getters.getOrgById(orgId);
      if (localOrg) {
        return new Promise(resolve => resolve(localOrg));
      } else {
        return axios
          .get(singleOrgUrl(orgId))
          .then(response => commit("addOrg", prepOrgObjData(response.data)))
          .then(() => getters.getOrgById(orgId))
          .catch(() => console.error("Get orgs error"));
      }
    },
    fetchMembersPage({commit}, pageRequest) {
      /* TODO: Right now members are not being saved, so every update and query goes to the backend.
      This makes it easier to ensure updates are reflected, but it might mean there is too much traffic.
      See if this can be improved, solely within the store, so pages can still be fetched, but only when
      needed. This would require the store to understand the internals of the query, for example any sort
      order change means going to the backend, but page changes without sort changes should be cacheable
       */
      return axios
        .get(membersUrl(pageRequest.orgId), pageRequest.query)
        .catch();
    },
    fetchPendingMembers({commit, dispatch}, pageRequest) {
      return axios.get(
        membersUrl(pageRequest.orgId) + "?status=UNCONFIRMED",
        pageRequest.query
      );
    },
    saveOrg({commit}, orgData) {
      let promise;
      let commitAction;
      if (orgData.id) {
        promise = axios.put(singleOrgUrl(orgData.id), orgData, axiosConfig);
        commitAction = "editOrg";
      } else {
        promise = axios.post(orgsUrl, orgData, axiosConfig);
        commitAction = "addOrg";
      }
      return promise.then(result => {
        commit(commitAction, prepOrgObjData(result.data));
        return result.data;
      });
    },
    patchOrg({commit}, orgData) {
      const url = singleOrgUrl(orgData.id);
      return axios.patch(url, orgData, axiosConfig).then(result => {
        let updatedData = prepOrgObjData(result.data);
        commit("patchOrg", updatedData);
        return updatedData;
      });
    },
    saveMember({commit}, memberData) {
      let url = membersUrl(memberData.orgId);
      let promise;
      if (memberData.isCreate) {
        promise = axios.post(url, memberData.member, axiosConfig);
      } else {
        url += "/" + memberData.member.email;
        promise = axios.put(url, memberData.member, axiosConfig);
      }
      return promise;
    },
    removeMember({commit}, data) {
      const url = membersUrl(data.orgId) + "/" + data.email;
      return axios.delete(url);
    },
    resetMemberMfa({commit}, data) {
      const url = membersUrl(data.orgId) + "/" + data.email + "/mfa";
      return axios.delete(url);
    },
    findOrgsByAbn({getters}, abn) {
      let url = orgsUrl + "?abn=" + abn;
      return axios.get(url).then(response => {
        const orgs = response.data.content;
        if (orgs) {
          return orgs;
        }
        return Promise.reject(new Error("Invalid response from server"));
      });
    },
    findOrgsByNzbn({getters}, nzbn) {
      let url = orgsUrl + "?nzbn=" + nzbn;
      return axios.get(url).then(response => {
        const orgs = response.data.content;
        if (orgs) {
          return orgs;
        }
        return Promise.reject(new Error("Invalid response from server"));
      });
    },
    fetchOrgsWithPageRequest({commit}, pageRequest) {
      return axios.get(orgsUrl, pageRequest.query);
    },
    joinOrg({commit}, joinRequest) {
      const url = orgsUrl + "/" + joinRequest.org + "/join";
      return axios.post(url, joinRequest, axiosConfig);
    },
    deleteOrg({commit}, orgId) {
      return axios.delete(singleOrgUrl(orgId)).then(result => {
        commit("deleteOrg", orgId);
        return result;
      });
    },
    registerUser({commit}, memberData) {
      let url = usersUrl(memberData.orgId);
      return axios.post(url, memberData.member, axiosConfig);
    },
  },
};
