const suggestionsUrl = (config, query, maxSearchResults, viewPort) =>
  "https://autocomplete." +
  config.domain +
  "/" +
  config.version +
  "/suggest.json?app_id=" +
  config.appId +
  "&app_code=" +
  config.appCode +
  "&maxresults=" +
  maxSearchResults +
  "&query=" +
  query +
  "&prox=" +
  viewPort.lat +
  "," +
  viewPort.lng +
  "," +
  viewPort.radius;

const geoCodeUrl = (config, query) =>
  "https://" +
  config.domain +
  "/" +
  config.version +
  "/geocode.json?app_id=" +
  config.appId +
  "&app_code=" +
  config.appCode +
  "&searchtext=" +
  query;

export default {
  namespaced: true,
  actions: {
    fetchAddressSuggestions({dispatch}, data) {
      return dispatch("configStore/fetchHereMapsConfig", null, {
        root: true,
      }).then(config =>
        fetch(
          suggestionsUrl(
            config,
            data.query,
            data.maxSearchResults,
            data.viewPort
          )
        )
          .then(response =>
            response.json().then(res => ({
              status: response.status,
              ok: response.ok,
              data: res,
            }))
          )
          .then(apiResponse => {
            if (apiResponse.ok) {
              return apiResponse.data;
            } else {
              throw (
                "Error finding address suggestions : HTTP " + apiResponse.status
              );
            }
          })
      );
    },
    fetchAddressGeoLocation({dispatch}, query) {
      return dispatch("configStore/fetchHereMapsConfig", null, {
        root: true,
      }).then(config =>
        fetch(geoCodeUrl(config, query))
          .then(response =>
            response.json().then(res => ({
              status: response.status,
              ok: response.ok,
              data: res,
            }))
          )
          .then(apiResponse => {
            if (apiResponse.ok) {
              if (apiResponse.data.Response) {
                let geoTime = apiResponse.data.Response.MetaInfo
                  ? apiResponse.data.Response.MetaInfo.Timestamp
                  : null;
                let geoView = null;
                if (
                  apiResponse.data.Response.View &&
                  apiResponse.data.Response.View.length > 0 &&
                  apiResponse.data.Response.View[0].Result.length > 0
                ) {
                  geoView = apiResponse.data.Response.View[0].Result[0];
                }
                return {
                  geoTime: geoTime,
                  geoView: geoView,
                };
              }
            } else {
              throw (
                "Error finding address geo location : HTTP " +
                apiResponse.status
              );
            }
          })
      );
    },
  },
};
