<template>
  <div class="loading-floater">
    <h3>Loading...</h3>
    <img src="../../content/images/favicon.svg" id="logo" alt="smartyfile" />
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "loadingPage",
  methods: {
    ...mapActions("auth", ["validate"]),
  },
  mounted() {
    this.validate().then(validUser => {
      if (validUser) {
        this.$router.push("/organisation");
      } else if (this.$router.currentRoute.path !== "/maintenance") {
        //only redirect to login when not in maintenance
        this.$router.push("/login");
      }
    });
  },
};
</script>

<style lang="css" scoped>
.loading-floater {

  top: 120px;
  left: 0;
  width: 100%;
  height: calc(100vh - 77px - 150px);
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo {
  width: 300px;
  animation: 5s infinite ease-in-out alternate b-icon-animation-fade;
}
</style>
