import axios from "axios";
import Vue from "vue";

export const allPermissions = [
  {
    key: "ADMIN",
    label: "Manage Organisation",
    inRoles: ["ADMIN"],
    view: "DEFAULT",
    info: "This includes permissions to manage organisation profile and user access.",
  },
  {
    key: "OF_VIEW",
    label: "View Organisation Profile",
    inRoles: ["ADMIN", "AUTHOR"],
    view: "DEFAULT",
    info: "Users with manage organisation permission automatically have access to view organisation profile.",
  },
  {
    key: "GRANT_APP_CREATE",
    label: "Create / Start Items",
    inRoles: ["ADMIN", "AUTHOR"],
    view: "DEFAULT",
  },
  {
    key: "GRANT_APP_WRITE",
    label: "Edit Items",
    inRoles: ["ADMIN", "AUTHOR", "WRITER"],
    view: "ACCESS_VIEW",
    values: "All / Limited",
    info: "Changing submission ownership/user is restricted to users with Administrator roles.",
  },
  {
    key: "GRANT_APP_READ",
    label: "View Items",
    inRoles: ["ADMIN", "AUTHOR", "WRITER", "READER"],
    view: "ACCESS_VIEW",
    values: "All / Limited",
    info: "Users with Create/Start and Edit Items permission automatically have access to view submissions.",
  },
  {
    key: "MAYBE_SUBMIT",
    label: "Submit Items",
    inRoles: ["ADMIN", "AUTHOR"],
    view: "SUBMIT_VIEW",
    values: "Optional",
    info: "This permission needs to be further configured on individual user level. Go to Users tab and click Edit user to set this permission.",
  },
];

export default {
  namespaced: true,
  state: {
    roles: {
      ADMIN: "Administrator",
      AUTHOR: "Author",
      WRITER: "Contributor",
      READER: "Viewer / Analyst",
      CUSTOM: "Custom",
    },
    allPermissions: allPermissions,
    orgs: {},
  },
  mutations: {
    addCustomPermissions(state, data) {
      if (data.permissions && data.permissions.length > 0) {
        Vue.set(state.orgs, data.orgId, data.permissions);
      } else {
        Vue.set(state.orgs, data.orgId, []);
      }
    },
  },
  getters: {
    getNameForGroup: state => group => state.roles[group] || "",
    getRolesWithKey: state =>
      Object.keys(state.roles).map(role => ({
        value: role,
        text: state.roles[role],
      })),
    getCustomPermissions: state => orgId => state.orgs[orgId],
    getMaybeSubmitRoles: state => orgId => {
      const roles = state.allPermissions
        .find(perm => perm.key === "MAYBE_SUBMIT")
        .inRoles.slice();
      if (state.orgs[orgId].includes("MAYBE_SUBMIT")) {
        roles.push("CUSTOM");
      }
      return roles;
    },
  },
  actions: {
    getCustomPermissions({commit, getters}, orgId) {
      const localPermissions = getters.getCustomPermissions(orgId);
      if (localPermissions) {
        return new Promise(resolve => resolve(localPermissions));
      } else {
        return axios
          .get(getUrl(orgId))
          .then(response => {
            commit("addCustomPermissions", {orgId, permissions: response.data});
          })
          .then(() => getters.getCustomPermissions(orgId));
      }
    },
    setCustomPermissions({commit, getters}, data) {
      return axios
        .put(getUrl(data.orgId), data.permissions, axiosConfig)
        .then(response => {
          console.log("permissions saved");
          commit("addCustomPermissions", {
            orgId: data.orgId,
            permissions: response.data,
          });
          return true;
        });
    },
  },
};

const getUrl = orgId => "/api/v1/orgs/" + orgId + "/role";
const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
