import axios from "axios";

const applyOrgsUrl = (baseUrl, orgId) => baseUrl + "/v1/orgs/" + orgId;
const updateAppsUrl = (url, orgId) =>
  applyOrgsUrl(url, orgId) + "/applications";

const axiosConfig = (token, userId) => {
  let result = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  if (userId) {
    result.params = {userId: userId};
  }
  return result;
};

export default {
  namespaced: true,
  actions: {
    updateApplicationOwners({dispatch}, data) {
      return dispatch("configStore/fetchSgApplyUrl", null, {
        root: true,
      }).then(url =>
        axios.delete(
          updateAppsUrl(url, data.orgId),
          axiosConfig(data.token, data.userSgId)
        )
      );
    },
    fetchApplicationsSummary({dispatch}, data) {
      return dispatch("configStore/fetchSgApplyUrl", null, {
        root: true,
      }).then(url =>
        axios.get(applyOrgsUrl(url, data.orgId), axiosConfig(data.token))
      );
    },
  },
};
