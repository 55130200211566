import {Configuration, DefaultApi} from "@/sg_copy/swagger-generated";

const basePath = baseUrl => baseUrl + "/v1";

const apiConfig = (basePath, token) =>
  new DefaultApi(
    new Configuration({
      basePath: basePath,
      accessToken: token,
    }),
    basePath
  );

const getApi = async dispatch => {
  const urlPromise = dispatch("configStore/fetchSgApplyUrl", null, {
    root: true,
  });
  const tokenPromise = dispatch("auth/getToken", null, {root: true});
  return apiConfig(basePath(await urlPromise), await tokenPromise);
};

export default {
  namespaced: true,
  actions: {
    async getSubmissions({dispatch}, data) {
      const api = await getApi(dispatch);
      return api.getSubmissions(
        data.result ? data.result.nextStartIndex : 0,
        data.filter.sortSelected,
        data.filter.sortAscending ? "ASCENDING" : "DESCENDING",
        !!data.archived,
        (data.result ? data.result.pageNumber : 0) + 1,
        data.filter.searchText || undefined,
        data.filter.orgSelected || undefined,
        data.filter.statusSelected === 0 || data.filter.statusSelected
          ? data.filter.statusSelected
          : undefined,
        data.filter.roundSelected || undefined,
        data.filter.ownedBySelected || undefined,
        data.filter.sharedWithSelected || undefined,
        data.filter.formNameSelected || undefined,
        data.filter.responseSelected || undefined,
        data.filter.submittedDateFrom || undefined,
        data.filter.submittedDateTo || undefined,
        data.filter.dueDateFrom || undefined,
        data.filter.dueDateTo || undefined,
        data.filter.showSimpleSearch,
        data.filter.instanceSelected || undefined,
        data.filter.classieOrgIdSelected || undefined,
        data.filter.regionSelected || undefined
      );
    },
    async getApp({dispatch}, appId) {
      const api = await getApi(dispatch);
      return api.getApp(appId);
    },
    async getAppSummary({dispatch}, appId) {
      const api = await getApi(dispatch);
      return api.getAppSummary(appId);
    },
    async archiveApp({dispatch}, data) {
      const api = await getApi(dispatch);
      return api.archiveApp(data.appId, data.archive);
    },
    async deleteApp({dispatch}, appId) {
      const api = await getApi(dispatch);
      return api.deleteApp(appId);
    },
    async downloadPdf({dispatch}, responseId) {
      const api = await getApi(dispatch);
      return api.downloadPdf(responseId, false, {
        responseType: "blob",
      });
    },
    async getUserInstances({dispatch}) {
      const api = await getApi(dispatch);
      return api.getUserInstances();
    },
    async shareApp({dispatch}, data) {
      const api = await getApi(dispatch);
      return api.shareApp(data.appId, data.shareDto);
    },
    async changeUser({dispatch}, data) {
      const api = await getApi(dispatch);
      return api.updateOwner(data.appId, data.userId);
    },
    async getMembers({dispatch}, data) {
      const api = await getApi(dispatch);
      return api.getMembers(data.appId, data.operation);
    },
    async getUser({dispatch}) {
      const api = await getApi(dispatch);
      return api.getUser();
    },
    async assignOrg({dispatch}, data) {
      const api = await getApi(dispatch);
      return api.assignOrg(data.appId, data.orgId);
    },
    async removeOrg({dispatch}, submissionId) {
      const api = await getApi(dispatch);
      return api.removeOrg(submissionId);
    },
    async getClassieOrgTypes({dispatch}) {
      const api = await getApi(dispatch);
      return api.getClassieOrgTypes();
    },
    async getRegions({dispatch}) {
      const api = await getApi(dispatch);
      return api.getRegions();
    },
    async startVariation({dispatch}, submissionId) {
      const api = await getApi(dispatch);
      return api.startVariationForm(submissionId);
    },
  },
};
