import axios from "axios";
import router from "@/router/router.js";
import store from "@/store/index.js";

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
};

const setupInterceptor = () => {
  const onSuccess = res => res;

  const onError = err => {
    if (err.config.url.startsWith("/")) {
      const status = err.status || err.response.status;
      if (status === 403 || status === 401) {
        window.location = "/sso/login/oidc";
      } else if (status === 521) {
        // Server is down for scheduled maintenance. Redirect user to maintenance page
        store.dispatch("auth/logout");
        router.push("/maintenance");
      }
      return Promise.reject(err);
    } else {
      //this is from external api. do not redirect
      return Promise.reject(err);
    }
  };
  axios.interceptors.response.use(onSuccess, onError);
};

export default setupInterceptor;
