export const dataMapper = {
  contactDetails: function (o) {
    const contactObj = {
      firstName: "",
      lastName: "",
      position: "",
      addresses_merged: [],
      email: "",
      emails: [],
      phoneNumbers: [],
      websites: [],
    };
    if (o) {
      if (o.firstName) {
        contactObj.firstName = o.firstName;
      }
      if (o.lastName) {
        contactObj.lastName = o.lastName;
      }
      if (o.position) {
        contactObj.position = o.position;
      }
      if (o.addresses_merged && o.addresses_merged.length > 0) {
        contactObj.addresses_merged = o.addresses_merged;
      }
      if (o.email) {
        contactObj.email = o.email;
      }
      if (o.emails && o.emails.length > 0) {
        contactObj.emails = o.emails;
      }
      if (o.phoneNumbers && o.phoneNumbers.length > 0) {
        contactObj.phoneNumbers = o.phoneNumbers;
      }
      if (o.websites && o.websites.length > 0) {
        contactObj.websites = o.websites;
      }
    }
    return contactObj;
  },
  singleLineAddress: function (address) {
    let addr = address.addressLine1;
    if (address.addressLine2) {
      addr += ", " + address.addressLine2;
    }
    if (address.addressLine3) {
      addr += ", " + address.addressLine3;
    }
    if (address.addressLine4) {
      addr += ", " + address.addressLine4;
    }
    if (address.suburbLocality) {
      if (addr.length > 0) {
        addr += ", ";
      }
      addr += address.suburbLocality;
    }
    if (address.state) {
      addr += ", " + address.state;
    }
    if (address.postcode) {
      addr += ", " + address.postcode;
    }
    if (address.country) {
      addr += ", " + address.country;
    }
    return addr;
  },
};
