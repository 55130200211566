import axios, {AxiosError} from "axios";
import _ from "lodash";

const axiosConfig = {headers: {"Content-Type": "application/json"}};

/** URL definitions */
const filesUrl = "/api/v1/files";
const singleFileInfoUrl = fileId => filesUrl + "/" + fileId;
const mimeTypesUrl = "/api/v1/mimeTypes";

export const FILE_API_ERRORS = {
  UNKNOWN_ERROR: {
    code: "UNKNOWN_ERROR",
    message:
      "An error occurred while processing the request. " +
      "Please try again later, refresh browser or contact support if the error persists.",
  },
  NETWORK_ERROR: {
    code: "NETWORK_ERROR",
    message:
      "A network error occurred while communicating with server. " +
      "Please try again later, refresh browser or contact support if the error persists.",
  },
  API_DATA_ERROR: {
    code: "API_DATA_ERROR",
    message:
      "An error occurred while processing the request. " +
      "Please try again later, refresh browser or contact support if the error persists.",
  },
  EXCEEDS_MAX_SIZE: {
    code: "EXCEEDS_MAX_SIZE",
    //%maxFileSize% is a placeholder that needs to be replaced
    message: "The selected file must be less than %maxFileSize%.",
  },
  EXCEEDS_ORG_CAPACITY: {
    code: "EXCEEDS_ORG_CAPACITY",
    message:
      "Cannot save file. The organizational storage capacity has been exceeded.",
  },
  EXCEEDS_USER_CAPACITY: {
    code: "EXCEEDS_USER_CAPACITY",
    message: "Cannot save file. The user storage capacity has been exceeded.",
  },
  UNSUPPORTED_TYPE: {
    code: "UNSUPPORTED_TYPE",
    //%detectedMimeType% is a placeholder that needs to be replaced
    message:
      "The selected file type%detectedMimeType%is restricted by SmartyFile.",
  },
  SCAN_ERROR: {
    code: "SCAN_ERROR",
    message: "There was an error during the virus scan. Please try again.",
  },
  VIRUS_DETECTED: {
    code: "VIRUS_DETECTED",
    message:
      "A virus has been detected in the uploaded file. Please upload another file.",
  },
};

function prepFileDataForMultipartRequest(fileData) {
  const payload = new FormData();
  // NOTE: FormData implementation in the browser (Chrome 117) doesn't support setting the content type of each part separately,
  // so creating a Blob and setting the content type of this part in the blob.
  const jsonBlob = new Blob([JSON.stringify(fileData.dto)], {
    type: "application/json",
  });
  payload.append("dto", jsonBlob);

  if (fileData.file) {
    payload.append("file", fileData.file, fileData.file.name);
  }

  return payload;
}

export default {
  namespaced: true,
  state: {
    files: [],
    fileDetails: new Map(),
    isLoading: true,
    isLoadingMimeTypes: true,
    errors: FILE_API_ERRORS,
    mimeTypes: [],
  },
  mutations: {
    loadFinished: state => (state.isLoading = false),
    loadStart: state => (state.isLoading = true),
    mimeTypesLoadingStarted: state => (state.isLoadingMimeTypes = true),
    mimeTypesLoadingFinished: state => (state.isLoadingMimeTypes = false),
    setMimeTypes: (state, mimeTypes) => (state.mimeTypes = mimeTypes),
    setFiles: (state, files) => (state.files = files),
    addFile: (state, file) => state.files.push(file),
    updateFile: (state, file) => {
      const pos = state.files.findIndex(f => f.id === file.id);
      if (pos >= 0) {
        state.files.splice(pos, 1, file);
        state.fileDetails.delete(file.id);
      } else {
        // file does not exist in the store
        throw "Failed to update the file since the given file's information has not been loaded. Try refreshing the browser";
      }
    },
    deleteFile: (state, fileId) => {
      const pos = state.files.findIndex(f => f.id === fileId);
      if (pos >= 0) {
        state.files.splice(pos, 1);
      } else {
        // file does not exist in the store
        throw "Failed to delete the file since the given file's information has not been loaded. Try refreshing the browser";
      }
    },
    addFileDetails: (state, file) => state.fileDetails.set(file.id, file),
  },
  getters: {
    getFiles: state => state.files,
    getIsLoading: state => state.isLoading,
    isMimeTypeAllowed: state => mimeType => {
      return (
        state.mimeTypes.filter(type => {
          return type.name === mimeType && type.allowUpload;
        }).length > 0
      );
    },
    getFileById: state => id => state.fileDetails.get(id),
    getMimeTypeByName: state => mimeType => {
      return state.mimeTypes.find(type => {
        return type.name === mimeType;
      });
    },
  },
  actions: {
    fetchMimeTypes({commit}) {
      const queryParams = new URLSearchParams();
      queryParams.append("allowedTypesOnly", "true");
      commit("mimeTypesLoadingStarted");
      return axios
        .get(mimeTypesUrl + "?" + queryParams.toString(), axiosConfig)
        .then(response => {
          if (response.data && Array.isArray(response.data)) {
            commit("setMimeTypes", response.data);
            commit("mimeTypesLoadingFinished");
          } else {
            throw FILE_API_ERRORS["API_DATA_ERROR"];
          }
        });
    },
    fetchFiles({commit}, orgId) {
      const queryParams = new URLSearchParams();
      if (orgId !== "") {
        queryParams.append("orgId", orgId);
      }
      queryParams.append("showExpired", "true");
      commit("loadStart");
      return axios
        .get(filesUrl + "?" + queryParams.toString(), axiosConfig)
        .then(response => {
          if (response.data && Array.isArray(response.data)) {
            commit("setFiles", response.data);
          } else {
            throw FILE_API_ERRORS["API_DATA_ERROR"];
          }
        })
        .finally(() => {
          commit("loadFinished");
        });
    },
    fetchFileDetails({commit, getters}, fileId) {
      const localFile = getters.getFileById(fileId);
      if (localFile) {
        return new Promise(resolve => resolve(localFile));
      } else {
        return axios
          .get(singleFileInfoUrl(fileId))
          .then(response => commit("addFileDetails", response.data))
          .then(() => getters.getFileById(fileId));
      }
    },
    saveFile({commit}, fileData) {
      return axios
        .post(filesUrl, prepFileDataForMultipartRequest(fileData))
        .then(response => {
          if (response) {
            commit("addFile", response.data);
            return response.data;
          } else {
            throw FILE_API_ERRORS["API_DATA_ERROR"];
          }
        });
    },
    updateFile({commit}, fileData) {
      return axios
        .put(
          singleFileInfoUrl(fileData.fileId),
          prepFileDataForMultipartRequest(fileData)
        )
        .then(response => {
          if (response) {
            commit("updateFile", response.data);
            return response.data;
          } else {
            throw FILE_API_ERRORS["API_DATA_ERROR"];
          }
        });
    },
    deleteFile({commit}, fileId) {
      return axios.delete(singleFileInfoUrl(fileId)).then(response => {
        if (response.status === 204) {
          commit("deleteFile", fileId);
        } else {
          throw FILE_API_ERRORS["API_DATA_ERROR"];
        }
      });
    },
  },
};

export function processErrors(error, placeHolders = {}) {
  let allErrors = {
    fileValidationErrors: [],
    virusScannerError: null,
    otherErrors: [],
  };
  try {
    if (error instanceof AxiosError && error.message === "Network Error") {
      allErrors.otherErrors.push(findErrorMessageByCode("NETWORK_ERROR"));
      return allErrors;
    }

    if (error.response.status >= 400 && error.response.status < 500) {
      // Get the error details from the exception message
      allErrors.fileValidationErrors = extractErrorMessages(
        error,
        placeHolders
      );
    } else if (error.response.status >= 500 && error.response.status < 600) {
      // In case of 500, we display the first error only.
      const exceptionType = getExceptionType(error);
      if (exceptionType === "SCAN_ERROR") {
        allErrors.virusScannerError = findErrorMessageByCode("SCAN_ERROR");
      } else {
        allErrors.otherErrors.push(findErrorMessageByCode("UNKNOWN_ERROR"));
      }
    }
  } catch (error) {
    allErrors.otherErrors.push(findErrorMessageByCode("UNKNOWN_ERROR"));
  }

  return allErrors;
}

function getExceptionType(error) {
  const exceptionName = _.get(error, "response.data.exception");
  if (exceptionName) {
    if (exceptionName.includes("VirusScannerException")) {
      return "SCAN_ERROR";
    }
  }
  return "UNKNOWN";
}

function extractErrorMessages(error, placeHolders = {}) {
  let processedErrorMessages = [];

  // Using lodash _.get here to avoid null checking every nested object
  const errorDetailsJsonStr = _.get(error, "response.data.message");
  if (errorDetailsJsonStr) {
    const errorDetails = JSON.parse(errorDetailsJsonStr);
    errorDetails.errors.forEach(errorCode => {
      let message = findErrorMessageByCode(errorCode);
      message = replacePlaceHoldersInErrorMessages(
        message,
        errorCode,
        errorDetails,
        placeHolders
      );
      processedErrorMessages.push(message);
    });
  }

  return processedErrorMessages;
}

function replacePlaceHoldersInErrorMessages(
  errorMessage,
  errorCode,
  errorDetails,
  placeHolders
) {
  let message = errorMessage;
  // Replace placeholders if needed
  if (errorCode === "EXCEEDS_MAX_SIZE") {
    message = errorMessage.replace(
      "%maxFileSize%",
      placeHolders["maxFileSize"]
    );
  } else if (errorCode === "UNSUPPORTED_TYPE") {
    if (errorDetails.shortType) {
      message = errorMessage.replace(
        "%detectedMimeType%",
        " '" + errorDetails.shortType + "' "
      );
    } else {
      message = errorMessage.replace("%detectedMimeType%", " ");
    }
  }
  return message;
}

function findErrorMessageByCode(code) {
  const error = FILE_API_ERRORS[code].message;

  // If key is not found return unknown error
  if (!error) {
    return FILE_API_ERRORS["UNKNOWN_ERROR"].message;
  }

  return error;
}
